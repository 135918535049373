import React, { Component } from 'react';
// import logo from '../bilder/bild1.jpg';
// import myVigge from '../bilder/vigge1.gif';
import myHenke from '../bilder/notapp.gif';
class Error extends Component {
	render() {
    	return (
			<div align="center"><h1>Ajajaaaaj nu var det något som gick lite fel va? </h1>
				<div> <h1>Henke does not approve!</h1> </div>

			<div align ="center">	<img src={myHenke} alt="bild my bild" height="480"/></div>



					</div>


		);
	}
}
//<img src={myVigge} alt="bild my bild" height="480"/>
//<img src={logo} alt="bild my bild" />
//<iframe src="https://giphy.com/embed/8TCeQuuPNItNIX9sn6" width="480" height="270" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
//<iframe src="https://giphy.com/embed/9RXHweiFZMlBYOApe5" width="480" height="270" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
//<div><iframe src="https://giphy.com/embed/d5FbeJwkXz0bOunIYc" width="480" height="270" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
export default Error;
